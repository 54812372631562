
.filter-close-btn{
    display:none ;
}

/* Responsive Development Code By Syed  */
@media only screen and (max-width: 600px) {
    
    .filter-close-btn{
        display: initial;
    }
    .filter-close-btn i{
        position: absolute;
        right: 10%;
        margin-top: 6%;
        color: white;
    }
    .sidebar-style{
        position: fixed;
        width: 100vw !important;
        top: 0px;
        z-index: 1000;
        background-color: white;
        transition: transform 0.3s ease;
        padding: 10px;
        max-height: 90vh;
        overflow-y: auto;
    }
    .sidebar-modal{
        transform: translate(-100%);
        transition: transform 0.3s ease;

    }
}