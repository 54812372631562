.radio-btnn{
    margin-right: 10 !important;
    margin-top: 11 !important;
    
}
.active-link{
    background-color: #14716b !important;
    color: white !important;
}

.edit-btn{
    line-height: 1 !important;
    
}

.my-modal-password {
    width: 40vw !important;    
    max-width: 40vw !important;
} 
.address-modal {
    width: 40vw !important;    
    max-width: 40vw !important;
} 

.sidebar-title{
    color: black;
}
.css-14dclt2-Input {
    display: flex !important;
    height: 5px !important;
}
.css-1insrsq-control{
    display: flex !important;
    height: 5px !important;
    background-color: #e9ecef !important;
    color: #495057 !important;


}
.css-319lph-ValueContainer{
    align-items: stretch !important;
    padding: 8px 8px !important;
}
.css-1hb7zxy-IndicatorsContainer{

    align-items: stretch !important;
}

select {
    width: 200px;
    height: 50px;
    line-height: 50px;
    -webkit-appearance: menulist-button;  
    -moz-appearance:none;
  }

  .new-pass{
    position: absolute;
    left: 31rem;
    top: 6px;
  }
  .confirm-pass{
    position: absolute;
    left: 31rem;
    top: 6px;
  }

