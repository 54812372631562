.logo img {
    width: 90% !important;
    height: auto !important;
    margin-top: -5px;
}
.mobile-drawer{
    display: none;
}

@media only screen and (max-width: 900px) {
   
#Search_bar_header{
    width: 150px !important;
}

.mobile-drawer{
    display: initial;
    margin-left: 55px;
}
.logo-img{
    height: 45px !important;
    
}
.drawer-div{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 65vw;
    
}
.drawer-div a{
}
.drawer-div a p{
     display: flex;
    align-items: center;
    margin: 20px 20px;
    /* padding: 10px; */
    margin-bottom: 20px;
    font-weight: 500;
}

.active-link {
    color: white !important;
    background-color: #14716b !important;
  }
  .active-link p:hover{
    color:white;
  }
  .active-link p{
    
    color: white;
    
  }

}

