/*  */
.img-zoom {
  border-radius: "10px";
}

.MuiTooltip-tooltip {
  background-color: #f5f5f9 !important;
}

@media only screen and (max-width: 600px) {
  /* Responsive Development By Syed */
  .product-details-carousel{
    max-width: 99.333333% !important;
  }
  .product-details-carousal-arrow-left{
display: none;
    /* max-width: 20% !important; */
  }
  .product-details-carousal-arrow-right{
    display: none;
    /* max-width: 10% !important; */
  }

  .product-details-content .pro-details-quality{
    flex-wrap: wrap;
  }
   .pro-details-cart button {
    padding: 15px 25px 15px;
    margin: 20px;
}
}