.tab-order{
background-color: #71B095;
font-weight: 600;
color: whitesmoke;
}
.text-color{
    color: #1F7872 !important;
    font-weight: bold !important;
}
.catalogue-section-grid{
    height: 400px !important;
    overflow: auto !important; 
} 
