.customShow p {
  font-size: 18px;
  border: 1px solid #14716b;
  padding: 10px;
  border-radius: 5px;
  color: #14716b;
  &:hover {
    color: white;
    background-color: #14716b;
    transition: all 0.5s ease 0s;
    cursor: pointer;
  }
}
.needarangelayout {
  align-items: center !important;
}
.needarangelayout .ForwardRef-searchContainer-7 {
  order: 2 !important;
}
.needarangelayout .ForwardRef-icon-5 {
  fill: #14716b !important;
}
.needarangelayout .ForwardRef-root-1 {
  height: 40px !important;
  background: #f3ece894 !important;
}

.customecolorme h4 {
  color: white !important;
}
.custom_about {
  font-weight: 700;
  font-size: 32px !important;
}
.custom_Fpasswaord {
  gap: 15px;
}
.custom_modal_center {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
// .modal {
//     position: fixed !important;
//     top: 50% !important;
//     left: 50% !important;
//     transform: translate(-50%,-50%) !important;
// }

.login-toggle-btn a {
  // font-size: 18px !important;
  display: inline-block;
  position: relative;
  color: #14716b !important;
}
.login-toggle-btn a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #14716b;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.login-toggle-btn a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.custom_sidebar .sidebar-style {
  // border: 1px solid gray;
  box-shadow: 1px 3px 10px rgb(0 0 0 / 20%);
  border-radius: 18px;
}
.customEditbtn {
  padding: 10px;
  width: 15%;
}
.customEditbtn:hover {
  background-color: #14716b;
  color: white !important;
  transition-duration: 100ms;
}
.custom_business_radio {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.custom_business_radio label {
  margin-bottom: 0px;
  padding-right: 10px;
}

.custom_padding {
  padding: 10px;
}
.header-top-area .header-top-wap {
  // display: flex;
  padding: 15px 0px !important;
}
.custom_price {
  padding-top: 10px;
}
.custom_price p {
  font-size: 20px;
}
.category-title {
  font-size: 22px;
}
.product-wrap-4 {
  border-radius: 5px;
}

.why_us_img {
  padding: 50px 25px !important;
}
.Custom_whyus {
  font-size: 22px !important;
  font-weight: 500;
  margin-bottom: 20px;
}
.Custom_whyus_cards {
  margin: 0px 20px;
}
.Custom_contact_nomargin {
  margin-bottom: 0px;
}
.main-menu nav ul li > a {
  line-height: 60px;
}
.stick .main-menu nav ul li a {
  line-height: 60px !important;
}
.main-menu nav ul li > a {
  font-size: 16px !important;
}
.Custom_whatpeoplesay {
  padding: 0px 0px;
}
.Custom_peopleSaymain {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 75px !important;
  margin: 100px 0px !important;
}
.Custom_Combo_offer {
  margin: 0px 50px;
}
.combo-offer-background {
  background-position: right;
}
.support-wrap-2.support-shape:before {
  // position: absolute;
  // top: 50%;
  // right: -14px;
  // width: 1px;
  // height: 61px;
  // content: "";
  // -webkit-transform: translateY(-50%);
  // transform: translateY(-50%);
  // background-color: #d5d4d4;
  display: none;
}
.support-wrap-2.support-shape {
  margin: 0px 20px;
}
.Custom_shop_btn {
  display: flex;
  align-items: center;
}
.select-all {
  font-size: 16px !important;
  color: black;
}
.Custom_clearall_btn {
  position: absolute;
  right: -18% !important;
  top: 8px;
  display: flex;
  gap: 30px;
  align-items: center;
}
.Custom_shop_FilterRow {
  align-items: center !important;
}
.Custom_clearbtnsubclass {
  border: 1px solid #14716b;
  color: #14716b;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.Custom_clearbtnsubclass:hover {
  background-color: #14716b;
  color: white;
  transition: 0.2s;
}
.Loginandregister {
  border-radius: 5px;
}
.custom_link_green a {
  color: #14716b !important;
}
.custom_changepassword {
  border: 1px solid #14716b;
}
.custom_shop_filter h3 {
  font-size: 14px !important;
  margin: 0px !important;
  margin-top: 3px !important;
}
.custom_shop_filter {
  padding: 10px 10px;
  border-bottom: 1px solid #dee2e6 !important;
}
.custom_shop_filter li ul {
  margin-top: 15px;
}
.custom_linkdiv1 .custom_linkdiv2 {
  margin: 0px !important;
  padding: 0px 15px;
  border-bottom: 1px solid #dee2e6;
}
.custom_count {
  background-color: #14716b !important;
}

.add_ {
  border-top: none !important;
}

.custom_checkout_align label {
  margin-left: 2px;
  align-items: flex-start;
}
.custom_checkout_align {
  width: 100% !important;
}
.custom_row_align {
  margin-left: 0px !important ;
  margin-right: 0px !important;
}
.Custom_payment_head h4 {
  margin-bottom: 0px;
}

.custon_sidebar_myaccount {
  border-radius: 18px;
  // background-color: white !important;
  box-shadow: 1px 3px 10px rgb(0 0 0 / 20%);
}
.blog-comment-wrapper .single-comment-wrapper {
  display: block;
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
  padding: 20px 0px !important;
}
.custom_collection_button button {
  color: red;
  border: none;
  // border-bottom: 1px solid red;
  background-color: transparent;
}
// .collection {
//   padding: 20px 20px !important;
//   padding-top: 0px !important;
// }
.Custom_collection_hr {
  background-color: red !important;
  margin: 0px !important;
}

.custom_fontweight_price {
  font-weight: 600;
}
.custom_addtocartbtnhome {
  text-transform: inherit !important;
}
.category_link {
  font-size: 16px !important;
}
.custom_shop_insidefilter {
  font-size: 12px !important;
}
.custom_shop_filter li div div svg {
  font-size: 20px !important;
}
.custom_shopall {
  font-size: 14px !important;
  margin-bottom: 0px !important;
}
.custom_addcollectionclear {
  display: inline-flex;
  padding: 5px;
  // width: 200px !important;
  justify-content: space-between !important;
  width: -webkit-fill-available;
}

.filter-label {
  font-size: 12px !important;
}

.custom_headerdeletebtn {
  padding: 0px 5px !important;
  color: #14716b !important;
  font-size: 12px !important;
  border: 1px solid #14716b !important;
  border-radius: 5px !important;
  margin-top: 5px;
}

.shop-total {
  font-weight: 600;
  color: #14716b;
}
.shopping-cart-total h4 {
  font-weight: 600 !important;
}
.shopping-cart-content {
  padding: 10px 20px 10px !important;
}
.shopping-cart-content ul {
  margin-right: -10px !important;
}

.custom_icon_size {
  font-size: 30px !important;
  margin-left: 20px;
}

.footer-list ul li {
  margin: 5px 0px;
}

.custom_checkout_btn {
  // border: none !important;
  // font-weight: 600 !important;
  background-color: transparent;
  padding: 3px 10px;
  margin: 0px 5px;
}
.custom_checkout_btn11 {
  color: #14716b;
  border: 1px solid #14716b;
  border-radius: 5px;
}
.custom_checkout_btn11:hover {
  color: white;
  background-color: #14716b;
}

.custom_checkout_btn22 {
  color: #c20000;
  border: 1px solid #c20000;
  border-radius: 5px;
}
.custom_checkout_btn22:hover {
  color: white;
  background-color: #c20000;
}
.custom_delivary_address h4 {
  text-transform: capitalize;
}

.grand-totall h4.grand-totall-title {
  font-size: 19px;
  font-weight: 600;
}
.grand-totall a {
  border-radius: 5px;
}
.single-my-account .myaccount-info-wrapper {
  padding: 0px 20px;
}

.tab-order {
  background-color: #14716b !important;
}

.table-hover tbody tr:hover {
  background-color: #14716b18;
  cursor: pointer;
  color: #14716b !important;
}
.table-hover tbody tr:hover td a {
  // color: #14716b !important;
}

.custom_myorder_row {
  padding: 20px 30px;
  border-radius: 5px !important;
}
.custom_myorder_inline {
  display: flex !important;
  gap: 25px;
}
.custom_myorder_inline h5 {
  margin-bottom: 0px !important;
}
.custom_mainMyorderrow {
  align-items: center !important;
}
.custom_myorder_row:hover {
  background-color: #14716b18 !important;
}

.hoverChange:hover,
.hoverChange:focus {
  background: transparent !important;
}

.custom_colorfiltermenu {
  // background-color: red;
  max-height: 400px !important;
  // overflow-y: scroll;
  top: 50px !important;
  bottom: auto !important;
  transform: none !important;
  overflow: hidden;
}
.dropdown-menu {
  z-index: 100 !important;
}
.custom_progressbar li:after {
  height: 5px !important;
  top: 13px !important;
}
.custom_progressbar li:before {
  width: 30px !important;
  height: 30px !important;
  line-height: 32px !important;
  font-size: 15px !important;
}
.custom_orderdetails_data {
  margin-top: 10px;
}
.custom_totalorder_row {
  justify-content: center !important;
}
.custom_paymentinfo {
  justify-content: center;
  margin-right: 0px;
}
.custom_table_data td {
  text-align: left;
}
.custom_table_data td:nth-child(2) {
  text-align: right !important;
}
.custom_box_store {
  border: none !important;
}
.custom_box_store tr {
  border-top: 1px solid #dee2e6;
}
.custom_box_store tr td {
  border: none;
}
.custom_box_store tr:nth-child(1) {
  border-top: none !important;
}

.custom_ships_to {
  display: inline-flex;
  align-items: center;
  gap: 25px;
}
.custom_ships_to h5 {
  margin-bottom: 0px;
}
.custom_ships_to .icon-color {
  font-size: 30px !important;
}
.custon_orderItem_store .MuiOutlinedInput-notchedOutline {
  // border: 2px solid black !important;
  border: none !important;
}
.custon_orderItem_store {
  // border: 1px solid green !important;
  box-shadow: 1px 3px 10px rgb(0 0 0 / 20%);
}

.custom_colorfiltermenu::-webkit-scrollbar {
  width: 5px;
  background-color: white;
}

.custom_box_store td:nth-child(1) {
  text-align: left;
}
.custom_box_store td:nth-child(2) {
  text-align: right;
}
.custom_box_store tr:nth-child(1) td {
  text-align: center;
}
.custom_address_myaccount {
  padding: 20px;
  // padding-bottom: 0px;
}
.custom_editanddeletebtn {
  // text-align: right;
  // width: 100%;
}
.Custom_addreddEditbtn {
  background-color: transparent;
  border: 1px solid #14716b;
  border-radius: 5px;
  margin: 0px 10px;
  // margin-bottom: 10px;
  color: #14716b;
  padding: 4px 20px;
}
.Custom_addreddEditbtn:hover {
  color: white;
  background-color: #14716b;
}
.Custom_addreddDeletebtn {
  background-color: transparent;
  border: 1px solid #c20000;
  border-radius: 5px;
  margin: 0px 10px;
  color: #c20000;
  // margin-bottom: 10px;
  padding: 4px 20px;
  margin-right: 20px;
}
.Custom_addreddDeletebtn:hover {
  background-color: #c20000;
  color: white;
}
.custom_address_rowGap {
  margin: 25px 0px;
}
.custon_addressName {
  text-transform: capitalize !important;
}
.custom_editFormSubmitBtn {
  padding: 0.375rem 0.75rem !important;
}
.Custom_modalBody_form {
  padding: 0px 20px !important;
}
.custom_new_pass {
  position: absolute;
  left: 90%;
}

.custom_label_payment span {
  padding-right: 0px;
}
.single-my-account .myaccount-info-wrapper {
  margin-left: 30px;
}

.custom_collection_a a {
  font-size: 14px !important;
}
.custom_collection_a {
  display: inline-flex;
  align-items: center;
}
.custom_coolection_sidebarlast li:last-child {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}
.custom_coolection_sidebarlast {
  border-radius: 18px;
}
.cart-shiping-update-wrapper .cart-shiping-update > a {
  background-color: #14716b;
  border-radius: 5px !important;
}
.cart-shiping-update-wrapper .cart-shiping-update > a:hover {
  background-color: #71b095;
  color: black !important;
}

.cart-shiping-update-wrapper .cart-clear > button:last-child {
  border-radius: 5px;
  background-color: #14716b;
}
.cart-shiping-update-wrapper .cart-clear > button:last-child:hover {
  color: black;
  background-color: #71b095;
  // background-color: #71b095   ;
}
.custom_shop_filter:last-child {
  border-bottom: none !important;
}

.Custom_collection_heading {
  padding: 20px;
  border-radius: 0px;
  background-color: #14716b;
  color: white;
  font-size: 21px;
  text-align: left;
  font-weight: 500;
}
.custom_Collection_backcolor {
  background-color: #71b095 !important;
}

.cust_myaccount_botborder {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.custom_myaccount_divalign {
  display: inline-flex;
  align-items: center;
}
.custom_myaccount_divalign a {
  margin-bottom: 0px !important;
}
.custom_myaccount_divalign a {
  text-align: left;
}

.custom_myaccount_onactive .active-link {
  background-color: #71b095 !important;
}
.main-menu nav ul li > a {
  margin: 0px 0px !important;
}
.custom_push_header {
  padding: 10px 0px;
  align-items: center !important;
}

.custom_pdpcollection_popup_inside ul {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}
.custom_pdpcollection_popup_inside ul::-webkit-scrollbar {
  width: 5px;
}
.custom_pdpcollection_popup_inside::-webkit-scrollbar {
  width: 5px;
}
.custom_collection_products_list {
  align-items: center;
  justify-content: space-between;
}
.custom_collection_products_list div:last-child {
  margin-right: 3vw;
}
.custom_savecollectionbutton {
  cursor: pointer !important;
}
.custom_collection_products_list .h5 {
  margin-bottom: 0 !important;
}
.custom_collection_listed {
  position: relative;
}
.custom_collection_listed .checkbox-color {
  top: 50% !important;
  transform: translateY(25%) !important;
}
.custom_checkboxon_shoppage {
  color: white !important;
}
.custom_pdpcollection_popup_inside {
  width: 31vw !important;
}
.custom_row_collection_btn {
  justify-content: center;
}
.custom_pdpcollection_popup_btn {
  background-color: #14716b;
  color: #dee2e6;
  padding: 10px;
}
.custom_search_clearbtn h3 {
  text-align: center;
  border: 1px solid #14716b;
  padding: 3px;
  margin-bottom: 0px;
  border-radius: 5px;
  color: #14716b;
  font-size: 16px !important;
}

.custom_search_clearbtn-1 h4 {
  text-align: center;
  border: 1px solid #14716b;
  margin-bottom: 0px;
  border-radius: 5px;
  color: white;
  font-size: 16px !important;
}
.custom_search_clearbtn h3:hover {
  background-color: #14716b;
  color: white;
  transition: 0.2s;
}
.custom_search_price {
  top: 50px !important;
  bottom: auto !important;
  transform: none !important;
}

.custom_conInfo_warp {
  padding: 0px;
}
.custom_contact_form input {
  font-weight: 700;
  padding: 0px !important;
  border: none !important;
  border-bottom: 2px solid #14716b !important;
  width: 100%;
}
.custom_contact_form button {
  margin-top: 0px !important;
  width: 100%;
}
.custom_twitter_icon {
  // font-size: 28px;
  margin-top: -5px;
  height: 30px;
  width: 30px;
  filter: invert(30%) sepia(1%) saturate(0%) hue-rotate(357deg) brightness(93%)
    contrast(88%);

  &:hover {
    filter: invert(30%) sepia(99%) saturate(367%) hue-rotate(127deg)
      brightness(97%) contrast(90%);
  }
}
.custom_contact_social ul li a {
  font-size: 28px !important;
}
.custom_social_icons li {
  margin-top: 50px !important;
}
.downloadAppStore {
  display: inline-flex;
}

.downloadAppStore img {
  width: 100%;
  height: auto;
}
.downloadAppStore a img {
  width: 100%;
  height: auto;
}

.downloadAppStore img {
  width: 50%;
  height: auto;
}

.downloadAppStore img:first-child {
  margin-right: 10px;
}
.downloadAmyaraApp {
  margin-top: 50px;
}
.downloadAmyaraApp h3 {
  margin-bottom: 30px;
  font-weight: 500;
}
.custom_contact_formlayout {
  padding: 0px 80px 0px 80px;
  background-color: white;
}
.custom_conInfo_warp {
  background-color: white;
}
.custom_conInfo_warp .custom_h3_head {
  font-weight: 500 !important;
  margin-left: 60px !important;
}
.custom_info_dec h3 {
  font-weight: 500 !important;
}
.custom_info_dec p {
  line-height: 1.5 !important;
}
.custom_contact_social {
  margin-top: 0px;
}
.custom_contactUsmainheading h2 {
  padding-left: 60px;
  font-weight: 500;
}
.custom_FAQ ul {
  list-style: circle !important;
  padding-left: 50px;
  padding-top: 5px;
}
.custom_FAQ ul li {
  padding: 5px 0px;
}
.custom_faq_question {
  font-size: 18px !important;
}
.Custom_product_count {
  font-size: 15px;
  color: gray;
  font-weight: 500;
}

.Custom_peopleSaymain {
  background-position: 140px 40% !important;
}
#how_we_work {
  background-position: center 65% !important;
}
.custom_inLoop {
  background-position: center 35% !important;
}
// .custom_addtocollectionbtn{
//   position: absolute !important;
//   bottom: 0 !important;
// }

.custom_wishlist_table {
  width: 100%;
  padding-right: 30px;
}
.custom_wishlist_table td:last-child {
  padding-right: 30px;
}
.product-wishlist-cart {
  // display: flex;
}

.Addtocart_custom {
  color: white;
  background-color: #14716b;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
}

.btn:hover {
  text-decoration: none !important;
}

.btn-link {
  text-decoration: none !important;
}

.btn-link:hover {
  text-decoration: none !important;
}

.custom_ATCremoveBtn {
  border: 0px;
  background-color: transparent;
  color: #666;
}
.custom_wishlist_actionBTN {
  display: flex;
  justify-content: center;
}
.custom_wishlist_actionBTN tr {
  display: flex !important;
  justify-content: space-between !important;
  gap: 50px;
}
.custom_wishlist_actionBTN tr td {
  padding: 0px !important;
}

.dropdown-item-1 .row {
  display: flex;
  flex-wrap: nowrap;
}
.dropdown-menu-price {
  min-width: 10rem !important;
}
.cart-item-variation span {
  width: 150%;
}

// .custom_cat_card{
// height: 48vh;
// }

.custom_cat_card img {
  height: 100%;
  width: 100%;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}
.shop-select {
  text-align: center !important;
}
.custom_allpassword_container {
  position: relative;
}

.header-right-wrap {
  // border-right: 1px solid lightgray !important;
  // border-left: 1px solid lightgray !important;
}
.custom_button_alignment {
  justify-content: flex-start;
  gap: 14vw;
}
// .product-content h3{
// text-transform: lowercase !important;
// }
// .product-content h3{
// text-transform: capitalize !important;
// }
// .custom_addcollectionclear p{
//   width: 100% !important;
// }
// .alice-carousel__stage-item{
//   width: 100% !important;
// }
// .alice-carousel__stage-item .custom_addcollectionclear {
// width: 100% !important;
// }

.logo {
  margin-top: 0px !important;
}
#slider-text {
  width: 700px !important;
}
.custom_group-icons,
.header-right-wrap {
  margin-top: 0px !important;
}
.custom_group-icons {
  padding: 0px 45px;
}

.custom_need_in_oneline {
  justify-content: space-between;
  align-items: center !important;
}
.custom_shareAllCollection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  gap: 5px;
  border: 1px solid #14716b;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.5s;
}
.custom_shareAllCollection h6 {
  margin: 0px;
  font-size: 16px;
}
.custom_shareAllCollection:hover {
  background-color: #14716b;
}
.custom_shareAllCollection:hover h6 {
  color: white;
}
.icon-color {
  transition: all 0.5s;
}
.custom_shareAllCollection:hover .icon-color {
  fill: white !important;
}
.custom_marginBottom_col-lg-4 {
  margin-bottom: 25px !important;
}
.blog-comment-wrapper {
  height: 100% !important;
}
.custom_share_link_button {
  color: #14716b !important;
}
.cart-item-variation {
  color: black;
}
.custom_color_ammount {
  font-size: 15px !important;
}
.custom_all_bootstrap_btn {
  padding: 6px 12px !important;
}
.custom_arrow_bigger button span {
  font-size: 30px !important;
}
.custom_address_name {
  font-size: 16px !important;
}
.custom_print_invoice h3 {
  color: #207973 !important;
  font-weight: 700 !important;
}
.custom_print_invoice p {
  color: black !important;
}
.custom_print_button {
  border: 1px solid #207973 !important;
  color: #207973;
}
.custom_print_button:hover {
  background-color: #207973;
}
.custom_orderID_name {
  font-size: 20px !important;
}
.custom_payment_information {
  color: #207973;
  font-size: 20px !important;
  font-weight: 700 !important;
}
.custom_ship_to {
  font-weight: 700 !important;
}
