.filter-btn{
    display: none;
}
/* Responsive Development Code By Syed  */

@media only screen and (max-width: 600px) {
.Custom_clearall_btn{
  position: static !important;
  margin-left: 20% !important;
 
}

.Custom_clearbtnsubclass {
    padding: 0;
    padding: 2px 30px !important;
    margin-right: 10px;
}
.Custom_shop_FilterRow{
    display: flex;
    gap: 1rem;
}
.text-left p{
    font-size: 15px;
}
.btn{
    line-height: 1.1rem;
    font-size: 0.9rem !important;
}
.filter-btn button{
   border: none;
   padding: 12px 10px;
   border: 1px solid #14716b;
   background-color: white;
   border-radius: 5px;
   color: #14716b;
}
.filter-btn{
    display: initial;
    margin-left: 2%;
}

}