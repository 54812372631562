#Search_bar_header {
  /* margin: 22px 0px; */
  /* padding: 36px 0px; */
  height: 50px;
  display: flex;
  justify-content: space-between;
  width: 95%;
}

.toastify-custom {
  z-index: 99999999 !important;
}

body {
  overflow-x: hidden;
}
/* In your stylesheet */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/*  */

.main-menu nav ul li {
  font-size: 16px !important;
}
.main-menu nav ul li > a {
  font-size: 16px !important;
  margin: 10px 0px;
}
.pull-right {
  text-align: right;
  margin-left: 0px;
}
.login-btn {
  /* border: 1px solid; */
  padding: 5px 19px;
  margin-left: 75px;
  /* font-family: "Poppins"; */
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #14716b;
  border-radius: 5px;
  color: #ffffff !important;
  background-color: #14716b;
}
.login-btn a {
  color: white !important;
}

.login-btn-1 {
  /* border: 1px solid; */
  padding: 3px 19px;

  /* margin-left: 75px; */
}

.Register-btn a {
  color: white !important;
}
.Register-btn {
  width: max-content;
  margin-left: 15px;
  border: 1px solid;
  padding: 5px 19px;
  border-radius: 5px;
  background-color: #14716b;
  color: white !important;
  justify-content: right;
  font-size: 16px;
  border: 1px solid #14716b;
}
.header-top-area .header-top-wap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group-icons {
  /* margin-left: 5px; */
  margin-top: -35px;
}
.header-top-area .header-top-wap {
  display: flex;
  padding: 5px 0px;
  align-items: center;
  justify-content: space-between;
}
/* .logo {
  margin-top: 26px;
} */
.header-icons {
  color: #747474;
}
.main-menu nav ul li:hover a {
  color: #237a75;
}
.slider-height-2 {
  height: 500px;
}
/* .logo {
  margin-top: 15px;
} */
#Search_bar_header {
  /* margin: 12px 0px; */
  /* padding: 36px 0px; */
  /* height: 48px; */
  display: flex;
  justify-content: space-between;
}
.main-menu nav ul li > a {
  font-size: 20px;
  margin: 10px 0px;
  font-weight: 500;
  line-height: 60px;
  display: inline-block;
  letter-spacing: 0.8px;
  color: #555252;
}
.header-top-area {
  border-bottom: 1px solid #e5e5e5;
}
#slider-text {
  padding-right: 20px;
  width: 800px;
  /* border-right: 1px solid lightgray; */
}
.icon-cart {
  /* border-right: 1px solid lightgray !important; */
  padding-right: 20px !important;
}
.footer-area {
  padding: 40px;
  position: relative;
}
.bg-gray {
  background-color: #ffffff;
}
.social-icons {
  font-size: 36px !important;
  /* margin-left: 30px */
}
.scroll-top {
  position: none;
  z-index: 9811 !important;
  right: 20px;
  bottom: 60px;
  /* visibility: hidden; */
  overflow: hidden;
  width: 50px;
  height: 50px;
  text-align: center;
  opacity: 0;
  color: #fff;
  border: none;
  border-radius: 50px;
  background: none;
  background-color: #247b75;
}
.about-section-1 {
  background-color: #1d7770;
  color: white !important;
  padding: 115px;
  font-size: 24px;
  text-align: justify;
}
.about-section-2 {
  padding-left: 0px;
  padding-right: 0px;
}
.about-text-1 {
  color: white;
  font-size: 20px;
}
.about-img-1 {
  width: inherit;
  /* padding-left: 50px; */
}
.about-section-3 {
  font-size: 48px !important;
  height: 515px;
  padding: 200px 100px 50px 100px;
  background: #d7e8e1;
}
.about-section-5 {
  background: #d7e8e1;
}
.about-vertical-text {
  transform: rotate(-90deg);
}
.about-text-4 {
  font-size: 20px;
  /* padding: 115px; */
  padding: 200px 0px;
  text-align: center;
}
.about-section-5 {
  padding-right: 50px;
}
.MuiAccordion-root {
  background-color: azure;
}
.close-button,
.share-button {
  cursor: pointer;
}
.collection {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  /* padding: 15px 30px 40px 30px !important; */
  margin-top: 40px !important;
  padding: 20px 20px !important;
  padding-top: 0px !important;
}
.collection-products {
  /* background-color: lightgray; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px !important;
  margin-top: 20px !important;
}
.delete-button,
.remove-item {
  cursor: pointer;
  color: red !important;
}

.css-hyxlzm {
  margin-top: 10px !important;
}

.delete-button,
.remove-item:hover {
  cursor: pointer;
  color: red !important;
}

.pointer {
  cursor: pointer;
}

.sidebar-white {
  /* height: 100%; */
  border-radius: 18px;
  background-color: white !important;
  box-shadow: 1px 3px 10px rgb(0 0 0 / 0.2);
}
.box-shadow {
  border-radius: 18px;
  border: 1px solid #ede9e9;
  height: 100%;
  background-color: white !important;
  box-shadow: 1px 3px 10px rgb(0 0 0 / 0.2);
}
.box-shadow-cart {
  border-radius: 18px;
  border: 1px solid #ede9e9;
  height: 28rem;
  background-color: white !important;
  box-shadow: 1px 3px 10px rgb(0 0 0 / 0.2);
}
.bg-white {
  background-color: white !important;
}
.My-cart {
  padding: 45px 30px 50px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  /* background-color: #f9f9f9; */
}
.strikethrough {
  text-decoration: line-through;
  color: gray;
}
.discount {
  color: #14716b;
  font-weight: 800;
}
.payment-options {
  font-weight: 700 !important;
}
.text-bold {
  font-size: 20px;
  font-weight: bold !important;
}
.cart-table-content table tbody > tr td.product-quantity {
  width: 220px;
}
.select-box {
  border-top: 0.5px solid gray !important;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.display-flex {
  display: flex;
}
.catalogue-img {
  height: 150px !important;
  width: auto;
}
.catalogue-section-grid {
  height: 400px !important;
  overflow: auto !important;
}
.icon-color {
  fill: #14716b !important;
}
.icon-color-wishlist {
  fill: #f5f5f5 !important;
}
.checkbox-color {
  color: #14716b !important;
  border-radius: 4px;
  border: #14716b;
}
.border-green {
  border: #14716b !important;
  border: solid !important;
  border-width: 1px !important;
}
.delete-btn {
  color: red;
}
.title-text {
  color: #207973 !important;
}
.title-text-bold {
  font-weight: 500 !important;
}
.addcart-btn {
  border-radius: 8px;
  border: none !important;
}
.border-round {
  border-radius: 5px;
}
.btn-background {
  border: 1px solid #14716b;
  background-color: #14716b;
  padding: 10px;
}

.catalogue-section-grid {
  height: 400px !important;
  overflow: auto !important;
}
.bottom-bar {
  z-index: 1000 !important;
  width: 100% !important;
  position: fixed !important;
  bottom: 0px;
  left: 14px;
  background-color: #0c0c0cc2 !important;
}
.combo-offer-background {
  /* background-image: url(/static/media/combo-bg.7458b549.jpg); */
  padding: 79px 0px;
  min-height: 491px;
  margin-bottom: 150px;
  background-repeat: no-repeat;
  background-size: cover;
}
.product-img {
  max-height: 340px;
  border-radius: 5px !important;
}
.section-title-5 h2 {
  font-size: 35px;
  line-height: 38px;
  margin: 0;
  color: #010101;
}
.product-wrap .product-img-badges,
.product-list-image-wrap .product-img-badges {
  position: absolute;
  top: 10px;
  padding: 7px 0px;
  background-color: white;
  border-radius: 50%;
  color: green;
  right: 14px;
}
.hr-white {
  border-top: 1px solid rgb(255 255 255 / 10%) !important;
}
.slider-home-right {
  position: absolute;

  /* top: 45px; */
  padding: 50% 0;

  color: antiquewhite;
  right: -75px;
}
.slider-home-left {
  position: absolute;
  left: -75px;
  /* top: 45px; */
  padding: 50% 0;
}
.topbar-dropdown {
  width: 100% !important ;
  left: -75px;
  color: #3b3838 !important;
  top: 45px;
  /* font-weight: 700 !important; */
}
.slider-home-left:hover,
.slider-home-right:hover {
  color: #14716b !important;
}
#category-title {
  text-align: center !important;
  font-size: 32px !important;
}
.header-icons {
  color: #247b75 !important;
}
.h3 {
  font-size: 38px;
  letter-spacing: 5px;
  color: #247b75;
  font-weight: 600;
  font-family: "Corinthia", sans-serif !important;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 18px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #14716b;
  border-radius: 18px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0e5a55;
}
.white-box-shadow2 {
  position: relative;
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgb(0 0 0 / 10%);
  border-radius: 18px;
}
.white-box-shadow {
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgb(0 0 0 / 10%);
  padding: 80px;
  border-radius: 18px;
  text-align: left;
  background: white;
}
.login-register-area {
  background-image: url(/static/media/c1.09be9e55.png);
  background-size: cover;
}
.login-form-container {
  background-color: white !important;
}

.login-register-wrapper .login-form-container {
  box-shadow: none !important;
  padding: 0px !important;
}
.otp-box {
  padding: 5px 0px;
}
.otp-box p,
.h6 {
  color: #0e5a55;
}
.modal-header {
  border-bottom: none !important;
}
.modal-content {
  border: none !important;
}

.bg-dark {
  background-color: #14716b !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #14716b !important;
}
.main-menu nav ul li {
  line-height: 30px;
  font-size: 20px;
  position: relative;
  display: inline-block;
  padding: 0 15px;
}
.product-wrap .product-content h3,
.product-list-image-wrap .product-content h3 {
  font-size: 18px;
  margin: 0;
}
.shop-now {
  padding: 10px 20px 10px 20px !important;
  color: #6ce1af !important;
  font-size: 20px !important;
  margin-left: 35px !important;
  margin-top: 50px !important;
  border: 1px solid #8cd9b8 !important;
}
.support-wrap-2.support-shape {
  box-shadow: 0 3px 10px rgb(118 209 169 / 80%);
  border-radius: 18px;
}
.support-content-2 {
  padding: 31px;
}
h5 {
  font-size: 18px !important;
}
.connect-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: White;
  opacity: 1; /* Firefox */
}
.single-image {
  overflow: hidden;
  border-radius: 18px;
  box-shadow: 0 3px 10px rgb(118 209 169 / 80%);
}
.css-1g86id8-MuiTreeItem-content {
  background-color: transparent !important;
}
.border-filter {
  margin-left: -20px;
  padding: 0px 0px;
  margin-right: 20px;
  /* border: 1px solid lightgrey; */
}
.all-catg {
  background-color: turquoise;
}
/* .filter-title{
  font-size: 20px;
} */
.sidebar-title {
  color: #010101;
}
.filter-title {
  text-transform: capitalize;
  font-size: 21px;
}
.main-menu nav ul li > a {
  font-size: 16px !important;
}
.product-tab-list a h4 {
  font-size: 18px !important;
}
.subscribe-text {
  font-size: 18px !important;
}
.mega-menu h5 {
  font-size: 16px !important;
}
.shop-select select,
.select-all {
  font-size: 18px;
}
.MuiTreeItem-label ::hover {
  color: #14716b !important;
}
.MuiTreeItem-label h4 {
  padding: 5px !important;
}
.font-600 {
  font-weight: 600;
}
.category_link {
  font-size: 18px;
}

.why_us_img {
  padding: 50px 0;
}
.why_us_img img {
  padding-bottom: 30px;
}
.add_ {
  font-size: 15px !important;
  line-height: 25px;
  border-top: 1px solid lightgray;
  padding-top: 10px;
}
@media only screen and (min-width: 992px) {
  .default-img {
    height: 340px !important;
  }
}

.my-modal {
  position: absolute;
  width: 68vw !important;
  max-width: 80vw !important;
}
/* .modal-content { */
/* right: -19% !important; */
/* position: initial; */
/* } */
.filter-label {
  margin-left: 8px;
  margin-top: 10px;
  font-size: 14px;
}

.filter-input {
  width: 18px;
}
.dropdown-menu-1 {
  padding: 0px 0px !important;
}

.dropdown-item-1 {
  padding: 0rem 1.5rem !important;
  margin-bottom: -12px !important;
}
.dropdown-menu-price {
  min-width: 20rem;
}

.css-5p5p6d-MuiSlider-root {
  color: #13706a !important;
  height: 6px !important;
}
.prd-swiper-slide {
  flex-shrink: 1 !important;
}

.login-input-1 {
  margin-bottom: 0 !important;
}
.login-error {
  margin-bottom: 30px !important;
}

.more-prd-img {
  /* border: 2px solid green; */
  border: 4px solid #1f7671;
}
.pass-icon-register {
  position: relative;
  right: -21rem;
  top: 1.6em;
}
.custom_ship_to {
  font-size: 700 !important;
}

.MuiStepIcon-active {
  color: rgba(0, 0, 0, 0.38) !important;
}

.MuiStepIcon-completed {
  color: #14716b !important;
}
.white-box-shadow2 {
  width: 60vw;
}
.login-with-otp {
  margin-top: 10px;
  color: #0e5a55;
  text-align: center;
}
.edit-btn-icon {
  color: #0e5a55;
}

@media only screen and (max-width: 900px) {
  .modal-container {
    height: 50vh;
  }
  #slider-text {
    width: 300px !important;
  }
  .pe-7s-menu {
    display: none;
  }
}

/* Scroll to top responsive code */
@media only screen and (max-width: 600px) {
  .colors_filter_menu_cards {
    width: 80vw !important;
  }
  .custom_plating_card_menu {
    width: 58vw !important;
  }

  .cart-product-thumbnail-image {
    display: block;
  }
  .white-otp-modal {
    padding: 10px !important;
  }
  .highlite {
    display: block !important;
  }
  .modal-container {
    height: 50vh !important;
  }
  .customEditbtn {
    width: 25% !important;
  }
  .modal-content {
    width: 90vw !important;
  }
  .custom_paymentinfo {
    margin-right: -30px;
  }
  .payment-information-pannel {
    padding: 1.5rem 0rem !important;
  }
  .myAccount-ColBar {
    margin-left: 280px;
  }
  .cust_myaccount_botborder {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .myaccountside-bar-white {
    width: 90vw;
    border-radius: 0px !important;
  }
  .sidebar-whitee {
    margin-top: 320px;
    flex: 1 0 75%;
    max-width: 100% !important;
  }

  .custom_delivary_address {
    max-width: 75% !important;
  }
  .mobile-num-section {
    max-width: 95% !important;
  }
  .edit-delete-button {
    flex: none;
    max-width: 100% !important;
  }
  .card-body {
    padding: 0rem;
  }
  .myaccount-info-wrapper {
    margin-left: 0px !important;
  }
  .save-button-modal {
    max-width: 100% !important;
  }
  .Edit-Collection-Box-Modal {
    width: 330px !important;
  }
  .create-collection-box-modal {
    width: 330px !important;
  }
  .shopping-cart-content {
    right: 0px !important;
    width: 330px;
    height: 420px !important;
  }
  .white-box-shadow2 {
    width: 95vw;
  }
  .text-center input {
    width: 85% !important;
  }
  .login-with-mobile {
    padding-top: 0rem !important;
  }
  .modal-text {
    line-height: 20px !important;
  }
  .otp-box {
    padding: 5px 0px;
  }
  .text-dark p {
    font-size: 16px;
  }
  .login-with-mobile h1 {
    font-size: 20px !important;
  }
  .login-with-mobile-two {
    padding: 0 !important;
  }
  .sidebar-title {
    font-size: 1rem !important;
  }
  .custom_collection_products_list {
    justify-content: normal !important;
  }
  .custom_pdpcollection_popup_inside {
    width: 95vw !important;
  }
  .custom_mainMyorderrow {
    overflow-x: scroll;
  }
  .share-on-whatsApp {
    width: 360px !important;
  }
  .share-on-whatsApp h4 {
    font-size: 16px;
  }
  .text-black h1 {
    text-align: center;
  }

  .login-toggle-btn a {
    float: none !important;
  }

  .white-box-shadow {
    padding: 35px;
  }

  .header-top-wap {
    justify-content: start !important;
  }
  .pe-7s-menu {
    display: none;
  }
  .custom_group-icons {
    padding: 0px 0px !important;
  }

  #slider-text {
    width: 200px !important;
    font-size: 14px;
  }
  .login-btn {
    padding: 5px 10px;
    font-size: 12px;
    margin-left: 40px !important;
  }
  .Register-btn {
    font-size: 12px;
    margin-left: 8px !important;
  }
  .scroll-top {
    right: 30px;
  }
  .footer-logo img {
    width: 65%;
  }
  .save-delete-logo-btn {
    margin-top: 15px !important;
  }
}

@media only screen and (max-width: 400px) {
  .colors_filter_menu_cards {
    width: 70vw !important;
  }
  .highlite {
    display: block;
  }
  .login-btn {
    margin-left: 25px !important;
  }
}
