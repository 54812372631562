.user-profile{
    text-align: center !important;
}
.customusername{
    text-transform: capitalize;
}
.customnumbercart span{
    font-size: 12px;
    line-height: 21px;
    position: absolute;
    top: -9px;
    right: 5px !important;
    display: inline-block;
    width: 21px;
    height: 21px;
    text-align: center;
    color: #fff;
    border-radius: 100%;
    background-color: #000;
}

.Register-btn{
    border-radius: 5px !important;
}

.needarangelayout{
    align-items: center !important;
}
.needarangelayout .ForwardRef-searchContainer-7{
    order: 2 !important;
}
.needarangelayout .ForwardRef-icon-5 {
    fill: #14716b !important;
}
.needarangelayout .ForwardRef-root-1 {
    height: 40px !important;
    background: #f3ece894 !important;
}

@media only screen and (max-width: 900px)  {
    .customusername-section{
        display: none !important;
    }
    .username-in-drawer p{
        margin: 20px 0px !important;
        font-weight: 700;
        color: #14716b;
        font-size: 20px;
       }
       .logout-button{
           width: 70%;
           margin-left: 30px;
           margin-top: 5px;
           height: 40px;
           background-color: #14716b;
           color: white;
           border: none;
           outline: none;
           border-radius: 5px;
       }


}


@media only screen and (max-width: 600px)  {
    .username-in-drawer p{
     margin: 20px 0px !important;
     font-weight: 700;
     color: #14716b;
     font-size: 20px;
    }
    .logout-button{
        width: 70%;
        margin-left: 30px;
        margin-top: 5px;
        height: 40px;
        background-color: #14716b;
        color: white;
        border: none;
        outline: none;
        border-radius: 5px;
    }
}


