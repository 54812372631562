.communication {
  position: fixed;
  top: 72%;
  right: 2%;
  z-index: 1000;
  height: 200px;
 }
 .video-call-logo{
  display: block;
  margin-bottom: 8px;
  margin-left: -5px;
  width: 50px;
}
.how-to-use-youtube{
  cursor: pointer;
  display: block;
  margin-left: -5px;
  width: 50px;
}
.whatsApp-icon{
  cursor: pointer;
  margin-left: -5px;
  height: 50px;
}
 /* .modall {
 position: fixed;
 right: 8%;
 top: 42%;
 z-index: 10000;
 background: white;
   border-radius: 5px;
   width: 290px;
   opacity: 0; 
 transform: translateY(20px); 
 animation: fadeIn 0.3s ease forwards; 
}


@keyframes fadeIn {
 from {
   opacity: 0;
   transform: translateY(20px);
 }
 to {
   opacity: 1;
   transform: translateY(0);
 }
}
 .modal-headerr {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
   background-color: #247B75;
   border-radius: 5px 5px 0px 0px;
   color: white;
   padding: 5px 0px;
 }
 .icon-group-logoo{
   display: flex;
   align-items: center;
   margin-left: 10px;
   padding: 10px 0px;
 }
 .icon-group-logoo h6{
  margin-top: 5px;
   margin-left: 10px;
   font-size: 16px;
   font-weight: 600;
   color: white;
 }
 .error-message{
   margin-left: 10px;
   font-weight: 500;
 }
 
 .close-btn {
   cursor: pointer;
   font-size: 30px;
   margin-right: 10px;
 }
 .modal-content-div {
   display: flex;
   flex-direction: column;
 }
 .msg-heading{
   margin-left: 10px;
   font-size: 16px;
   font-weight: 600;
 }
 .input-text-area-whatsApp{
  resize: none;
  margin: 0px 10px;
width: 90%;

 }
 input,
 textarea {
 

   resize: none;
   background-color: #F2F2F2;
   margin: 10px 10px;
   height: 100px;
   padding: 8px;
   border: 1px solid #ccc;
   font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   /* border-radius: 5px; */
 
 
 /* .send-button {
   background-color: #247B75;
   color: white;
   border: none;
   padding: 12px 16px;
   border-radius: 5px;
   cursor: pointer;
   margin: 10px 10px;
   
 }
 .whatsApp-icon-btn{
   display: flex;
   align-items: center;
   z-index: 1000;
   width: 30px;
   height: 30px;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
   background-color: white;
   border-radius: 30px;
   
 }
 .get-support-heading{
  font-size: 12px;
  font-weight: 700;
  margin-right: 3px;
 }
 .whatsApp-icon{
   margin-left: -5px;
   height: 50px;
 }  */