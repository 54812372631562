
.connect-input {
  border: none;
  border-bottom: 2px solid white;
  padding: 5px 10px;
  outline: none;
  background: transparent;
  width: 80%;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.arrow-icon {
  font-size: 50px;
  margin-left: -2.5rem;
}
.connect-img {
  margin-top: -40px;
}
.catg-img {
  height: 70% !important;
  width: 100%;
}
.btn-color {
  background-color: #14716b !important;
  color: white;
  height: 38px;
  width: 100px;
}
.work-text {
  padding-left: 7rem;
  padding-right: 7rem;
}
.combo-content {
  font-size: 5em;
}
.cmb-img {
  padding: 0 !important;
  border: none !important;
}
.cmb-img-2 {
  padding: 0 !important;
  border: none !important;
  margin-top: 7rem;
}
.catg-text {
  font-size: 2rem;
  padding: 2rem;
}
.pull-right-catg {
  position: relative;
  top: 14rem;
}
.product-content-4 h2 {
  font-size: 32 !important;
  font-weight: 400;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1250px !important;
  }
}
.img-thumbnail {
  padding: 0rem !important;
  background-color: #fff;
  border: 0px solid #dee2e6 !important;
  border-radius: 0 !important ;
  max-width: 100%;
  height: auto;
}
.product-img {
  border-radius: 5% !important;
  box-shadow: 0 3px 10px rgb(118 209 169/ 0.8);
}
.product-wrap-4 {
  position: relative;
  overflow: hidden;
  border-radius: 18px;
  box-shadow: 0 3px 10px rgb(118 209 169 / 0.8);
}
.image-how-we-work {
  position: absolute;
  left: 8%;
  top: -30%;
  height: auto !important;
  box-shadow: 0 3px 10px rgb(118 209 169 / 0.8);
  border: 1px solid #76d1a9;
}
#how_we_work {
  margin: 100px 50px;
  padding: 60px 40px;
  border-radius: 18px;
  background-color: #1c212d;
  /* margin-left: 10px !important;
  margin-right: 10px !important; */
}
.cutomer-review {
  font-size: 18px;
}
.how-we-work-section {
  /* background-image: url("../../assets/img/bg-how.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  /* border: 2px solid #68aa8e; */
  /* padding: 35px; */
}
.nav-item {
  text-align: right !important;
}
.prod-list-col {
  /* width: 100%; */
  text-align: right;
  display: contents;
}
.nav {
  display: block !important;
}
.combo-offer-section {
  display: flex;
}
.combo-offer-background {
  background-image: url(../../assets/img/combo-bg.jpg);
  padding: 79px 0px;
  margin-bottom: 80px !important;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
h1,
h2,
h3 {
  font-family: "Cormorant Garamond";
}
h3 {
  font-size: 18px !important;
}
.single-banner-2 .banner-content-2 h3 {
  font-family: "Cormorant Garamond";

  font-size: 38px !important;
  margin: 0;
  color: #ffffff !important;
}

.single-banner-2 .banner-content-2 h4 {
  font-family: "Cormorant Garamond";
  font-size: 24px;
  font-weight: 500;
  margin: 22px 0 55px !important;
  color: #ffffff !important;
}
.single-banner-2 .banner-content-2 a {
  font-size: 14px;
  display: inline-block;
  width: auto !important;
  height: 28px;
  text-align: center;
  color: #ffffff !important;
  border: 0px solid #ffffff !important;
  border-radius: 100%;
}
.combo-border-box {
  border: 2px solid #68aa8e;
  padding: 25px;
}
.single-banner-2 .banner-content-2 h4 span {
  color: #68aa8e !important;
}
.single-banner-2 .banner-content-2 {
  position: absolute;
  left: 5px !important;
  width: 100%;
  bottom: -9% !important;
  transform: translateY(-50%);
}
.combo-heading {
  color: white;
  text-shadow: 3px 4px 5px #68aa8e;
  font-family: "Cormorant Garamond";
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 50px;
  letter-spacing: 1px;
}
.slider-height-2 {
  height: 90vh !important;
}
.nav-style-1 .ht-swiper-button-nav.swiper-button-next {
  right: 20px;
  left: auto;
}
.nav-style-1 .ht-swiper-button-nav.swiper-button-next-1 {
  right: -30px !important;
  left: auto;
  color: black !important;
}
.nav-style-1 .ht-swiper-button-nav.swiper-button-prev {
  font-size: 80px;
  position: absolute;
  top: 70%;
  left: 20px;
  display: inline-block;
  visibility: hidden;
  width: 80px;
  height: 80px;
  padding: 0;
  transition: all 0.3s ease 0s;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  color: #999;
  border: none;
  background: none;
}

.nav-style-1 .ht-swiper-button-nav.swiper-button-prev-1 {
  font-size: 80px;
  position: absolute;
  top: 70%;
  left: -30px !important;
  display: inline-block;
  visibility: hidden;
  width: 80px;
  height: 80px;
  padding: 0;
  transition: all 0.3s ease 0s;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  color: #999;
  border: none;
  background: none;
}
.button {
  display: inline-block;
  border-radius: 18px;
  background-color: #247b75;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  padding: 7px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.footer-area {
  position: relative;
  padding: 40px;
  color: #fff !important;
  font: 30px;
  background-color: #ffffffe8 !important;
  border-top: 1px solid #edebeb;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

/* .footer-title h3,.footer-list ul li a,.footer-list p{
  color: white !important;
}
.footer-lines{
  border-top: 1px solid rgb(255 255 255 / 15%);
} */

/* .bg-gray{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
} */
.facebook-icon {
  color: blue;
}
.instagram-icon {
  color: crimson;
}
.twitter-icon {
  color: lightskyblue;
}
.login-register-tab-list {
  display: flex !important;
}
.asterisk {
  color: red;
}
.main-menu nav ul li ul.mega-menu {
  position: absolute;
  z-index: 999;
  top: 100%;
  left: -544px !important;
  display: block;
  visibility: hidden;
  width: 1000px !important;
  padding: 40px;
  transition: all 0.5s ease 0s;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 1px 7px -2px rgb(0 0 0 / 30%);
}
.text-title {
  padding-left: 20px;
  font-weight: 500;
  font-size: 50px;
  text-align: start;
  /* font-family: 'Poppins'; */
  color: black !important;
}
.text-title h1 {
  text-align: center !important;
  padding-left: 20px;
  font-weight: 500;
  font-size: 50px;
  /* font-family: 'Poppins'; */
}
.main-menu nav ul li ul.mega-menu,
.main-menu nav ul li,
h5 {
  /* font-family: 'Poppins' !important ; */
}
h1,
h2,
h3,
h4 {
  /* font-family: 'Poppins' !important ;  */
}
.text-green {
  color: #14716b !important;
  padding-left: 20px;
  font-weight: 500;

  font-size: 71px;
  font-family: "Corinthia", sans-serif !important;
  /* text-shadow: 
    1px 0px 1px rgb(129, 127, 127), 0px 1px 1px #eee; */
}
.text-green2 {
  color: #6ae1ae !important;
  padding-left: 20px;
  font-weight: 500;

  font-size: 50px;
  font-family: "Corinthia", sans-serif !important;
  /* text-shadow: 
    1px 0px 1px #ccc, 0px 1px 1px #eee; */
}

.product-tab-list a h4 {
  letter-spacing: 1px;
  font-size: 21px !important;
  font-weight: 600;
  margin: 0;
  transition: all 0.3s ease 0s;
  color: #555;
}

/* Responsive CSS */
@media only screen and (max-width: 920px) {
  .slider-height-2 {
    height: 60vh !important;
  }
  .custom_price p {
    font-size: 16px !important;
  }
  .product-content h3 {
    font-size: 12px !important;
  }
  #how_we_work {
    margin: 40px 12px;
    padding: 20px 5px;
  }
  
  .text-green {
    font-size: 50px;
  }
}
/* Responsive Development Code By Syed  */

@media only screen and (max-width: 600px) {
  .nav-style-1 .ht-swiper-button-nav.swiper-button-prev{
    font-size: 50px !important;
  }
 
  .stay-in-loop-section{
    flex: 1 0 58.3333333333% !important;
    max-width: 70% !important;
  }
  .text-green2{
    padding-left: 0px !important;
  }
  .stay-in-loop-main{
    flex: 1 0 41.6666666667% !important;
    max-width: 80.666667% !important;
   
  }
  .connect-input{
    width: 100% !important;
  }
  .stay-in-loop-section h2{
    text-align: center;
    margin-left: 0px !important;
  }
  .single-brand-logo a{
    max-width: 45% !important;
  }
  .brand-logo-area{
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  }
  .whatsapp-img {
   width: 30%;
  }
  html{
    overflow-x: hidden;
  }
  
  .slider-height-2 {
    height: 30vh !important;
  }
  .text-title{
    font-size: 28px;
  }
  .share-icon svg{
    width: 0.5em;
    height: 0.5em;
  }
  .slider-home-left{
    left: -20px !important;
    font-size: 3em !important;
  }
  .slider-home-right {
    right: -35px !important;
    font-size: 3em !important;

  }

  
  .text-green{
    font-size: 36px;
  }
  .pb-50{
  padding-bottom: 15px  !important;
  }
  .pt-50{
padding-top: 15px !important;
  }
  .custom_price p {
    font-size: 16px !important;
  }
  .product-content h3 {
    font-size: 12px !important;
  }
  #how_we_work {
    margin: 60px 12px;
    padding: 40px 5px;
  }
  .support-wrap-2 .why_us_img{
    margin-bottom: 40px;
    padding: 20px 10px !important;
    text-align: center !important;
  }
  .bg-white{
    width: 100% !important;
  }
  .product-tab-list.pt-30, .product-tab-list.pt-55 {
    padding: 10px 0 10px !important;
}

.row .my-auto {
  flex: none;
  max-width: 100%;
  text-align: center;
}

  /* Whatsapp img styling */
}
.whatsapp-img {
  position: fixed;
  /* bottom: 2%;
  right: 1.5%; */
  bottom: 10%;
  right: 7%; 
  z-index: 10000;
  cursor: pointer;
}

